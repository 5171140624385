import AsyncStorage from "@react-native-async-storage/async-storage";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import Settings from "../../../config/Settings";
import { FormatAMPM } from "../../../core/datehelper";
import getUserId from "../../../core/GetToken";

export const GetAppointmentSlotsFromAPI = createAsyncThunk(
    "getAppointmentSlots/Appointment",
    async (values, { dispatch, getState }) => {
        dispatch(getAppSlots())

        if (!values.sameDayBooking && values.passingDate == moment(new Date()).format("YYYY-MM-DD")) {
            dispatch(getAppSlotsSameDayBooking({ msg: "Same day booking is not allowed" }))
            return false
        }
        if (values.advanceBookingDays && values.advanceBookingDays !== "0" && values.passingDate > moment(new Date()).add(values.advanceBookingDays, "days").format("YYYY-MM-DD")) {
            dispatch(getAppSlotsSameDayBooking({ msg: `Bookings can only be made up to ${values.advanceBookingDays} days in advance.` }))
            return false

        }
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);

            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")
            var raw = {
                PatientID: item.userid,
                PageSize: 50,
                ScheduleDate: values.passingDate,
                PractitionerID: values.practitionerId,
                AppointmentTypeID: values.typeid,
                LocationIDs: values.LocationIDs,
            }
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify(raw)
            };
            // token = data

            fetch(Settings.apiUrl + "Patient/AppointmentSlots", requestOptions)
                .then(data => data.json())
                .then(json => {
                    let amlist = [];
                    let pmlist = [];
                    //    alert(JSON.stringify(json))
                    if (json[0].hasOwnProperty('entry')) {

                        json[0].entry.map((item, index) => {
                            FormatAMPM(item.resource.start.split('+')[0]).includes('AM') ?
                                amlist.push(item) : pmlist.push(item)
                        })
                    }
                    //    formatAMPM(item.resource.start).includes('pm') &&
                    dispatch(getAppSlotsSuccess({ amlist, pmlist }))
                })
                .catch(err => dispatch(getAppSlotsFailed()))
        })

    }

);

export const AppointmentSlotsDataCleanup = createAsyncThunk(
    "appointmentSlotsDataCleanup/Appointment",
    async (values, { dispatch, getState }) => {
        dispatch(getAppSlotsCleanup())
    })
const GetAppointmentSlotsSlice = createSlice({
    name: "getAppointmentSlots",
    initialState: {
        isBooking: false,
        BookApp: [],
        BookAppPM: [],
        error: false,
        sameDayErrorMsg: null
    },
    reducers: {
        getAppSlots: (state, action) => {
            state.isBooking = true
            state.BookApp = []
            state.BookAppPM = []
            state.sameDayErrorMsg = null
        },
        getAppSlotsSuccess: (state, action) => {
            state.isBooking = false
            state.BookApp = action.payload.amlist
            state.BookAppPM = action.payload.pmlist
            state.sameDayErrorMsg = null

        },
        getAppSlotsFailed: (state, action) => {
            state.isBooking = false
            state.BookAppPM = []
            state.BookApp = []
            state.error = true
            state.sameDayErrorMsg = null

        },
        getAppSlotsCleanup: (state, action) => {
            state.isBooking = false
            state.BookApp = []
            state.sameDayErrorMsg = null
        },
        getAppSlotsSameDayBooking: (state, action) => {
            state.isBooking = false
            state.BookAppPM = []
            state.BookApp = []
            state.error = true
            state.sameDayErrorMsg = action.payload.msg
        },

    },
});




export const {
    getAppSlots, getAppSlotsFailed, getAppSlotsSuccess, getAppSlotsCleanup, getAppSlotsSameDayBooking
} = GetAppointmentSlotsSlice.actions;
export default GetAppointmentSlotsSlice.reducer;
