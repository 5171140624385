import AsyncStorage from "@react-native-async-storage/async-storage";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Settings from "../../../config/Settings";
import getUserId from "../../../core/GetToken";

export const GetAppSettingAPI = createAsyncThunk(
    "getAppSetting/AppSetting",
    async (values, { dispatch, getState }) => {

        dispatch(gettingAppSetting())
        getUserId().then((userInfo) => {
            if (userInfo == "none") {
                return
            }
            const item = JSON.parse(userInfo);
            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")
            var raw = JSON.stringify({
                PatientID: item.userid,
                PortalSettingTypeID: 1
            })
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: raw
            };

            let sent = '';
            let reply = '';
            let Face2FaceConfirmationMessage = '';
            let AppointmentShowReasonforContact = '';
            let IsShowBookAppointmentOptions = '';
            let DisableAppointment = '';
            let PortalRepeatMedSetting = '';
            let EnableFamilyMemberBooking = ''
            let RepeatRxUrgentNotes = '';
            let IsPickupPharmacyMandatory = '';
            let IsRxUrgent = '';
            let IsRxFax = '';
            let PortalMessageTermsandConditions = ''
            let PortalAppointmentTermsAndConditions = '';
            let PrefPharmacy = "";
            let PharmacyId = "";
            let ReasonForContact = "";
            let ShowPickupTypeInRepeatRx = "";
            let IsAllowSameDayBooking = "";
            let IsAllowSameDayBooking2 = "";
            let PaymentMethodType = "";
            let DisableAppointmentCOVID = "";
            let PaymentCardTypes = "";
            let IsOutstandingBalance = "";
            let OutstandingBalance = "";
            let ByPassLockDownPatientPortalAppointmentBookings = ""
            let IsCardTypeConfifiguration = ''
            let PortalTurnOffCovidQuestionnaire = ""
            let DisableMessaging = false
            let DisableMessagingCompose = false
            let IsShowPayNow = ""
            let IsShowOnPortal = ""
            let AllowPatientToUpdateProfile = ""
            let PatientcanUpdatePic = ""
            let BlockOutTimeForApptCancel = "0"
            let EnableRepeatRxInvoicing = false
            let HideMyProviderforMessaging = false
            let HideMyProviderforAppointmentbooking = false
            let ShowProviderforRepeatRxRequest = false
            let EnableadvanceappointmentbookingforXdays = "0"

            fetch(Settings.apiUrl + "Lookup/LoadPracticeSetting", requestOptions)
                .then(response => response.json())
                .then(json => {
                    json[0].entry.map((item, index) => {

                        if (item.resource.id == "PortalEnableReply")
                            reply = item.resource.extension[1].valueString && item.resource.extension[1].valueString == "1" ? true : false;
                        else if (item.resource.id == "Reasonforcontactprovidermapping")
                            ReasonForContact = item.resource.extension[1].valueString && item.resource.extension[1].valueString == "1" ? true : false;
                        else if (item.resource.id == "IsShowOnPortal")
                            IsShowOnPortal = item.resource.extension[1].valueString && item.resource.extension[1].valueString == "1" ? true : false;
                        else if (item.resource.id == "IsShowPayNow")
                            IsShowPayNow = item.resource.extension[1].valueString && item.resource.extension[1].valueString == "1" ? true : false;
                        else if (item.resource.id == "IsShowPickupTypeinRepeatRx")
                            ShowPickupTypeInRepeatRx = item.resource.extension[1].valueString && item.resource.extension[1].valueString == "1" ? true : false;
                        else if (item.resource.id == "PortalEnableCompose")
                            sent = item.resource.extension[1].valueString && item.resource.extension[1].valueString == "1" ? true : false; // item.resource.extension[1].valueString
                        else if (item.resource.id == "Face2FaceConfirmationMessage")
                            Face2FaceConfirmationMessage = item.resource.extension[1].valueString ? item.resource.extension[1].valueString : "";
                        else if (item.resource.id == "AppointmentShowReasonforContact")
                            AppointmentShowReasonforContact = item.resource.extension[1].valueString && item.resource.extension[1].valueString == "1" ? true : false;
                        else if (item.resource.id == "DisableAppointmentCOVID") { //0 covid disable none  //1 covid disable f2f //2 covid disable All
                            DisableAppointmentCOVID = item.resource.extension[1].valueString
                        }
                        else if (item.resource.id == "IsAllowSameDayBooking") {
                            IsAllowSameDayBooking = item.resource.extension[1].valueString && item.resource.extension[1].valueString == "1" ? true : false
                        }
                        else if (item.resource.id == "IsAllowSameDayBooking2") {
                            IsAllowSameDayBooking2 = item.resource.extension[1].valueString && item.resource.extension[1].valueString == "1" ? true : false
                        }
                        else if (item.resource.id == "PaymentMethodType") {
                            PaymentMethodType = item.resource.extension[1].valueString
                        }
                        else if (item.resource.id == "IsOutstandingBalance") {
                            IsOutstandingBalance = item.resource.extension[1].valueString && item.resource.extension[1].valueString == "1" ? true : false
                        }
                        else if (item.resource.id == "OutstandingBalance") {
                            OutstandingBalance = item.resource.extension[1].valueString
                        }
                        else if (item.resource.id == "PaymentCardTypes") {
                            PaymentCardTypes = item.resource.extension[1].valueString
                        }

                        else if (item.resource.id == "IsShowBookAppointmentOptions")
                            IsShowBookAppointmentOptions = item.resource.extension[1].valueString && item.resource.extension[1].valueString == "1" ? true : false
                        else if (item.resource.id == "DisableAppointment")
                            DisableAppointment = item.resource.extension[1].valueString && item.resource.extension[1].valueString == "1" ? true : false
                        else if (item.resource.id == "PortalRepeatMedSetting" && item.resource.extension[1].valueString)
                            PortalRepeatMedSetting = item.resource.extension[1].valueString ? item.resource.extension[1].valueString : 'If you wish to request anything different from your selected medications, either more, less or other type, please indicate so in the notes below. If you would like to have your prescription sent to a pharmacy, please indicate in the notes below the name of the pharmacy. Please note that some pharmacies have a charge to receive a faxed prescription - please check this with your pharmacy. Unless otherwise indicated, you must collect the prescription from our reception and pay for this service at the time of collection. Your prescription will be available after two working days. If this is more urgent, please contact the Reefton Health Medical Centre on 03 769 7440. Please note - repeat prescriptions are only available at your doctor’s discretion for stable medical conditions which have had medication previously prescribed by Reefton Health Medical Centre. A consultation with your doctor will be required every six months to allow a review of your medication.'
                        else if (item.resource.id == "EnableFamilyMemberBooking" && item.resource.extension[1].valueString)
                            EnableFamilyMemberBooking = item.resource.extension[1].valueString && item.resource.extension[1].valueString == "1" ? true : false
                        else if (item.resource.id == "RepeatRxUrgentNotes")
                            RepeatRxUrgentNotes = item.resource.extension[1].valueString ? item.resource.extension[1].valueString : "testing"
                        else if (item.resource.id == "IsPickupPharmacyMandatory")
                            IsPickupPharmacyMandatory = item.resource.extension[1].valueString && item.resource.extension[1].valueString == "1" ? true : false
                        else if (item.resource.id == "IsRxUrgent")
                            IsRxUrgent = item.resource.extension[1].valueString && item.resource.extension[1].valueString == "1" ? true : false
                        else if (item.resource.id == "IsRxFax") {
                            IsRxFax = item.resource.extension[1].valueString && item.resource.extension[1].valueString == "1" ? true : false
                        }
                        else if (item.resource.id == "PortalMessageTermsandConditions") {
                            PortalMessageTermsandConditions = item.resource.extension[1].valueString ? item.resource.extension[1].valueString : "N/A"
                        }
                        else if (item.resource.id == "PortalAppointmentTermsAndConditions") {
                            //  alert(item.resource.extension[1].valueString)
                            PortalAppointmentTermsAndConditions = item.resource.extension[1].valueString
                        }
                        else if (item.resource.id == "PrefPharmacy") {
                            PrefPharmacy = item.resource.extension[1].valueString ? item.resource.extension[1].valueString : "N/A"
                        }
                        else if (item.resource.id == "PharmacyId") {
                            PharmacyId = item.resource.extension[1].valueString ? item.resource.extension[1].valueString : 'N/A'
                        }
                        else if (item.resource.id == "ByPassLockDownPatientPortalAppointmentBookings") {
                            ByPassLockDownPatientPortalAppointmentBookings = item.resource.extension[1].valueString && item.resource.extension[1].valueString == "1" ? true : false
                        }
                        else if (item.resource.id == "IsCardTypeConfifiguration") {
                            IsCardTypeConfifiguration = item.resource.extension[1].valueString && item.resource.extension[1].valueString == "1" ? true : false
                        }
                        else if (item.resource.id == "PortalTurnOffCovidQuestionnaire") {
                            PortalTurnOffCovidQuestionnaire = item.resource.extension[1].valueString && item.resource.extension[1].valueString == "1" ? true : false
                        }
                        else if (item.resource.id == "DisableMessaging") {
                            DisableMessaging = item.resource.extension[1].valueString && item.resource.extension[1].valueString == "1" ? true : false
                        }
                        else if (item.resource.id == "DisableMessagingCompose") {
                            DisableMessagingCompose = item.resource.extension[1].valueString && item.resource.extension[1].valueString == "1" ? true : false
                        }
                        else if (item.resource.id == "AllowPatientToUpdateProfile") {
                            AllowPatientToUpdateProfile = item.resource.extension[1].valueString && item.resource.extension[1].valueString == "1" ? true : false
                        }
                        else if (item.resource.id == "PatientcanUpdatePic") {
                            PatientcanUpdatePic = item.resource.extension[1].valueString && item.resource.extension[1].valueString == "1" ? true : false
                        }
                        else if (item.resource.id == "BlockOutTimeForApptCancel") {
                            BlockOutTimeForApptCancel = item.resource.extension[1].valueString ? item.resource.extension[1].valueString : "0"
                        }
                        else if (item.resource.id == "EnableRepeatRxInvoicing") {
                            EnableRepeatRxInvoicing = item.resource.extension[1].valueString && item.resource.extension[1].valueString == "1" ? true : false
                        }
                        else if (item.resource.id == "HideMyProviderforMessaging") {
                            HideMyProviderforMessaging = item.resource.extension[1].valueString && item.resource.extension[1].valueString == "1" ? true : false
                        }
                        else if (item.resource.id == "HideMyProviderforAppointmentbooking") {
                            HideMyProviderforAppointmentbooking = item.resource.extension[1].valueString && item.resource.extension[1].valueString == "1" ? true : false
                        }
                        else if (item.resource.id == "ShowProviderforRepeatRxRequest") {
                            ShowProviderforRepeatRxRequest = item.resource.extension[1].valueString && item.resource.extension[1].valueString == "1" ? true : false
                        }
                        else if (item.resource.id == "EnableadvanceappointmentbookingforXdays") {
                            EnableadvanceappointmentbookingforXdays = item.resource.extension[1].valueString ? item.resource.extension[1].valueString : "0"
                        }

                    })
                    dispatch(gettingAppSettingSuccess(json))

                    _storeSettings(reply, sent, ReasonForContact, Face2FaceConfirmationMessage, AppointmentShowReasonforContact, IsShowBookAppointmentOptions, DisableAppointment, PortalRepeatMedSetting, EnableFamilyMemberBooking, RepeatRxUrgentNotes, IsPickupPharmacyMandatory, IsRxUrgent, IsRxFax, PortalMessageTermsandConditions, PortalAppointmentTermsAndConditions, PrefPharmacy, PharmacyId, ShowPickupTypeInRepeatRx, IsAllowSameDayBooking, IsAllowSameDayBooking2, PaymentCardTypes, DisableAppointmentCOVID, IsOutstandingBalance, OutstandingBalance, ByPassLockDownPatientPortalAppointmentBookings, PaymentMethodType, IsCardTypeConfifiguration, PortalTurnOffCovidQuestionnaire, DisableMessaging, IsShowPayNow, IsShowOnPortal, AllowPatientToUpdateProfile, PatientcanUpdatePic, BlockOutTimeForApptCancel, DisableMessagingCompose, EnableRepeatRxInvoicing, HideMyProviderforMessaging, HideMyProviderforAppointmentbooking, ShowProviderforRepeatRxRequest, EnableadvanceappointmentbookingforXdays)

                })
                .catch(err => dispatch(gettingAppSettingFailed(err)))
        })

    }

);
export const RefreshAppSettingData = createAsyncThunk(
    "refreshAppSettingData/AppSetting",
    async (values, { dispatch, getState }) => {
        dispatch(gettingAppSettingRefresh())
    })
const GetAppSettingSlice = createSlice({
    name: "getAppSetting",
    initialState: {
        isLoadingAppsetting: false,
        ApplicationSettings: [],
        error: false,
        isAvailable: false
    },
    reducers: {
        gettingAppSetting: (state, action) => {
            state.isLoadingAppsetting = true
            state.ApplicationSettings = []
            state.error = false
            state.isAvailable = false

        },
        gettingAppSettingSuccess: (state, action) => {
            state.isLoadingAppsetting = false
            state.ApplicationSettings = action.payload
            state.error = false
            state.isAvailable = true
        },
        gettingAppSettingFailed: (state, action) => {
            state.isLoadingAppsetting = false
            state.ApplicationSettings = []
            state.error = true
            state.isAvailable = false
        },
        gettingAppSettingRefresh: (state, action) => {
            state.isLoadingAppsetting = false
            state.ApplicationSettings = []
            state.error = false
            state.isAvailable = false
        },

    },
});



const _storeSettings = async (re, se, ReasonForContact, Face2FaceConfirmationMessage, AppointmentShowReasonforContact, IsShowBookAppointmentOptions, DisableAppointment, PortalRepeatMedSetting, EnableFamilyMemberBooking, RepeatRxUrgentNotes, IsPickupPharmacyMandatory, IsRxUrgent, IsRxFax, PortalMessageTermsandConditions, PortalAppointmentTermsAndConditions, PrefPharmacy, PharmacyId, ShowPickupTypeInRepeatRx, IsAllowSameDayBooking, IsAllowSameDayBooking2, PaymentCardTypes, DisableAppointmentCOVID, IsOutstandingBalance, OutstandingBalance, ByPassLockDownPatientPortalAppointmentBookings, PaymentMethodType, IsCardTypeConfifiguration, PortalTurnOffCovidQuestionnaire, DisableMessaging, IsShowPayNow, IsShowOnPortal, AllowPatientToUpdateProfile, PatientcanUpdatePic, BlockOutTimeForApptCancel, DisableMessagingCompose, EnableRepeatRxInvoicing, HideMyProviderforMessaging, HideMyProviderforAppointmentbooking, ShowProviderforRepeatRxRequest, EnableadvanceappointmentbookingforXdays) => {
    try {//AppointmentShowReasonforContact
        const jsonValue = JSON.stringify({ "send": se, "reply": re, "ReasonForContact": ReasonForContact, "Face2FaceConfirmationMessage": Face2FaceConfirmationMessage, "AppointmentShowReasonforContact": AppointmentShowReasonforContact, "IsShowBookAppointmentOptions": IsShowBookAppointmentOptions, "DisableAppointment": DisableAppointment, "PortalRepeatMedSetting": PortalRepeatMedSetting, "EnableFamilyMemberBooking": EnableFamilyMemberBooking, "RepeatRxUrgentNotes": RepeatRxUrgentNotes, "IsPickupPharmacyMandatory": IsPickupPharmacyMandatory, "IsRxUrgent": IsRxUrgent, "IsRxFax": IsRxFax, "PortalMessageTermsandConditions": PortalMessageTermsandConditions, PortalAppointmentTermsAndConditions, "PrefPharmacy": PrefPharmacy, "PharmacyId": PharmacyId, "ShowPickupTypeInRepeatRx": ShowPickupTypeInRepeatRx, "IsAllowSameDayBooking": IsAllowSameDayBooking, "IsAllowSameDayBooking2": IsAllowSameDayBooking2, "PaymentCardTypes": PaymentCardTypes, "DisableAppointmentCOVID": DisableAppointmentCOVID, "IsOutstandingBalance": IsOutstandingBalance, "OutstandingBalance": OutstandingBalance, "ByPassLockDownPatientPortalAppointmentBookings": ByPassLockDownPatientPortalAppointmentBookings, "PaymentMethodType": PaymentMethodType, "IsCardTypeConfifiguration": IsCardTypeConfifiguration, "PortalTurnOffCovidQuestionnaire": PortalTurnOffCovidQuestionnaire, "DisableMessaging": DisableMessaging, "IsShowPayNow": IsShowPayNow, "IsShowOnPortal": IsShowOnPortal, "AllowPatientToUpdateProfile": AllowPatientToUpdateProfile, "PatientcanUpdatePic": PatientcanUpdatePic, "BlockOutTimeForApptCancel": BlockOutTimeForApptCancel, "DisableMessagingCompose": DisableMessagingCompose, "EnableRepeatRxInvoicing": EnableRepeatRxInvoicing, "HideMyProviderforMessaging": HideMyProviderforMessaging, "HideMyProviderforAppointmentbooking": HideMyProviderforAppointmentbooking, "ShowProviderforRepeatRxRequest": ShowProviderforRepeatRxRequest, "EnableadvanceappointmentbookingforXdays": EnableadvanceappointmentbookingforXdays });
        //  alert(jsonValue)

        await AsyncStorage.setItem('@portalSettings', jsonValue)

    } catch (error) {
        // Error saving data
    }
};


export const {
    gettingAppSetting, gettingAppSettingFailed, gettingAppSettingSuccess, gettingAppSettingRefresh
} = GetAppSettingSlice.actions;
export default GetAppSettingSlice.reducer;
