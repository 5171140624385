// import { AsyncStorage } from "react-native";
import AsyncStorage from '@react-native-async-storage/async-storage';


const getMfaResponse = async () => {
    // let userId = '';
    // try {
    //     userId = await AsyncStorage.getItem('secureToken') || 'none';

    // } catch (error) {
    //     // Error retrieving data
    //     
    // }
    // 
    // return userId;


    let mfaresp = '';
    try {
        mfaresp = await AsyncStorage.getItem('MfaAuthResponse') || 'none';

    } catch (error) {
        // Error retrieving data

    }

    return mfaresp;
}

export default getMfaResponse